import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { getCompleteName, LayoutDialog, User, UserStatus } from 'telemed-core';

import { PatientStatusForm } from '../forms';

const WIDTH = 452;

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: WIDTH,
    display: 'grid',
    justifyItems: 'center',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    rowGap: theme.spacing(2.7),
    '& span': {
      width: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-end',
      '& button': {
        marginTop: 0,
      },
    },
    '& .MuiGrid-root': {
      width: WIDTH,
    },
    '& .MuiFormGroup-root': {
      // flexDirection: 'column',
      '& span': {
        justifyContent: 'flex-start',
      },
    },
    '& .MuiRadio-root': {
      width: 50,
    },
  },
}));

type Props = {
  open: boolean;
  patient: User;
  handleClose: (needsRefresh?: boolean) => void;
  updateStatus: (
    status: UserStatus,
    statusDescription?: string | undefined
  ) => Promise<void>;
};

export const UnsubscribePatientDialog: React.FC<Props> = ({
  open,
  patient,
  handleClose,
  updateStatus,
}) => {
  const classes = useStyles();

  return (
    <LayoutDialog
      title={`Dar de Baja a ${getCompleteName(patient)}`}
      open={open}
      handleClose={handleClose}
    >
      <Box>
        <PatientStatusForm
          className={classes.form}
          updateStatus={updateStatus}
          onBack={handleClose}
        />
      </Box>
    </LayoutDialog>
  );
};
