import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import { Layout, User } from 'telemed-core';
import { PatientForm } from '../../forms';

const keyPrefix = 'container.PatientContainer';

export const PatientContainer: React.FC<RouteComponentProps> = ({
  location,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { user } = location?.state as { user: User };

  return (
    <Layout title={t(`title.${!user ? 'new' : 'add'}`)}>
      <Container
        maxWidth='md'
        sx={{
          marginTop: (theme) => theme.spacing(5),
        }}
      >
        <PatientForm user={user} />
      </Container>
    </Layout>
  );
};
