import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import {
  AddressInput,
  AssignedDoctorInput,
  BloodTypeInput,
  Button,
  DateInput,
  EmailInput,
  EmergencyContactInput,
  GenderInput,
  MaritalStatusInput,
  MedicalInsuranceInput,
  Modal,
  NameInput,
  Loading,
  PhoneInput,
  User,
  BasicTextInput,
  InputLabel,
} from 'telemed-core';
import 'moment/locale/es-mx';

import { usePatientFormManagement } from '../hooks';
import FormControl from '@material-ui/core/FormControl';

const keyPrefix = 'forms.PatientForm';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
}

export const PatientForm: React.FC<Props> = ({
  className,
  user,
}): JSX.Element => {
  const { error, loading, methods, processedUsername, submit } =
    usePatientFormManagement(user);
  const { t } = useTranslation('translation', { keyPrefix });
  const navigate = useNavigate();

  const handleClickOk = () => {
    navigate('/patient/list');
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)} className={className}>
          <NameInput
            label={t('name.label')}
            placeholders={{
              name: t('name.placeholder.firstName'),
              firstLastName: t('name.placeholder.firstLastName'),
              secondLastName: t('name.placeholder.secondLastName'),
            }}
          />
          <Box
            sx={{
              pt: (theme) => theme.spacing(2.5),
              pb: (theme) => theme.spacing(2.5),
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(4,1fr)',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <BloodTypeInput
              label={t('bloodType.label')}
              placeholder={t('bloodType.placeholder')}
            />
            <GenderInput
              label={t('gender.label')}
              placeholder={t('gender.placeholder')}
            />
            <MaritalStatusInput
              label={t('maritalStatus.label')}
              placeholder={t('maritalStatus.placeholder')}
            />
            <DateInput
              label={t('birthday.label')}
              placeholder={t('birthday.placeholder')}
            />
          </Box>
          <Box
            sx={{
              pb: (theme) => theme.spacing(1),
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(3,1fr)',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <EmailInput
              label={t('email.label')}
              placeholder={t('email.placeholder')}
              disabled={!!user}
            />
            <PhoneInput
              label={t('phone.label')}
              placeholder={t('phone.placeholder')}
            />
            <PhoneInput
              name='cellPhone'
              label={t('cellPhone.label')}
              placeholder={t('cellPhone.placeholder')}
            />
          </Box>
          <FormControl fullWidth sx={{ pt: (theme) => theme.spacing(1) }}>
            <InputLabel label={t('occupation.label')} />
            <Box
              sx={{
                pt: (theme) => theme.spacing(0),
                pb: (theme) => theme.spacing(1),
                display: 'grid',
                alignItems: 'end',
                gridTemplateColumns: '1fr 1fr',
                columnGap: (theme) => theme.spacing(2),
              }}
            >
              <BasicTextInput
                name='currentOccupation'
                placeholder={t('occupation.currentOccupation.placeholder')}
                maxLength={100}
              />
              <BasicTextInput
                name='previousOccupation'
                placeholder={t('occupation.previousOccupation.placeholder')}
                maxLength={100}
              />
            </Box>
          </FormControl>

          <Box
            sx={{
              display: 'grid',
              rowGap: (theme) => theme.spacing(1),
            }}
          >
            <AddressInput
              label={t('address.label')}
              placeholders={{
                street: t('address.placeholders.street'),
                extNumber: t('address.placeholders.extNumber'),
                intNumber: t('address.placeholders.intNumber'),
                cp: t('address.placeholders.cp'),
                state: t('address.placeholders.state'),
                delegation: t('address.placeholders.delegation'),
                colony: t('address.placeholders.colony'),
                extraInfo: t('address.placeholders.extraInfo'),
              }}
              {...(!!user
                ? {
                    initialSets: {
                      cp: user.userInfo.address.cp,
                      colony: user.userInfo.address.colony,
                    },
                  }
                : {})}
            />
            <MedicalInsuranceInput
              label={t('insurance.label')}
              placeholders={{
                insurerName: t('insurance.placeholders.insurerName'),
                policyNumber: t('insurance.placeholders.policyNumber'),
              }}
            />
            <EmergencyContactInput
              label={t('emergencyContact.label')}
              placeholders={{
                emergencyContactName: t(
                  'emergencyContact.placeholders.emergencyContactName'
                ),
                emergencyContactRelationship: t(
                  'emergencyContact.placeholders.emergencyContactRelationship'
                ),
                emergencyContactPhone: t(
                  'emergencyContact.placeholders.emergencyContactPhone'
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: (theme) => theme.spacing(5),
              '& > div': {
                minWidth: 500,
              },
            }}
          >
            <AssignedDoctorInput
              label={t('doctor.label')}
              placeholder={t('doctor.placeholder')}
            />
          </Box>
          <Box
            sx={{
              m: (theme) => `${theme.spacing(5)} auto`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('error.title')} message={error} />}
      {!!processedUsername &&
        (!!user ? (
          <Modal
            title={t('success.update.title')}
            message={t('success.update.message')}
            onClickOk={handleClickOk}
          />
        ) : (
          <Modal
            title={t('success.add.title')}
            message={t('success.add.message', { email: processedUsername })}
            onClickOk={handleClickOk}
          />
        ))}
    </>
  );
};
