import React, { useState } from 'react';
import { TFunction } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {
  DoctorData,
  User,
  UserListItem,
  UserType,
  EditSvg,
  MailSvg,
  getCompleteName,
  UserStatus,
  Loading,
  Modal,
} from 'telemed-core';
import Moment from 'react-moment';
import 'moment/locale/es-mx';

import { Switch } from './Switch';
import { UnsubscribePatientDialog } from '../dialogs';
import { usePatientListItem } from '../hooks';

type Props = {
  user: User;
  refreshList: () => void;
  t: TFunction<'translation'>;
};

export const PatientListItem: React.FC<Props> = ({
  user,
  refreshList,
  t,
}): JSX.Element => {
  const { doctorData, creationDate } = user;

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {
    error,
    loading,
    usernameEmailSent,
    usernameStatusUpdated,
    statusDescription,
    edit,
    resend,
    updateStatus,
  } = usePatientListItem(user);

  const handleStatusChange = (checked: boolean) => {
    checked ? updateStatus(UserStatus.ACTIVE) : setOpenDialog(true);
  };

  return (
    <Box
      sx={{
        opacity:
          user.userInfo.status === UserStatus.DEFINITIVE_UNSUBSCRIBE ? 0.6 : 1,
      }}
    >
      <UserListItem
        user={user}
        extraPostData={
          <Typography variant='body2' color='grey.300' sx={{ opacity: 0.7 }}>
            {t('creation')}
            <Moment fromNow locale='es-mx'>
              {creationDate}
            </Moment>
          </Typography>
        }
        leftCol={
          <Switch
            activeText={t('switch.active')}
            definitiveUnsubscribeText={t('switch.inactive.permanent')}
            status={user.userInfo.status}
            statusDescription={statusDescription}
            temporaryUnsubscribeText={t('switch.inactive.temporary')}
            onChange={handleStatusChange}
          />
        }
        sxLeftCol={{ width: 110 }}
        rightCol={
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '200px 50px 50px',
              alignItems: 'center',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <Doctor doctorData={doctorData} />
            <IconButton
              size='large'
              color='primary'
              onClick={() => {
                resend();
              }}
              disabled={user.userInfo.status !== UserStatus.ACTIVE}
            >
              <MailSvg height={26} />
            </IconButton>
            <IconButton
              size='large'
              color='primary'
              onClick={() => {
                edit();
              }}
              disabled={user.userInfo.status !== UserStatus.ACTIVE}
            >
              <EditSvg height={26} />
            </IconButton>
          </Box>
        }
      />
      <UnsubscribePatientDialog
        open={openDialog}
        patient={user}
        handleClose={() => {
          setOpenDialog(false);
        }}
        updateStatus={updateStatus}
      />
      {loading && <Loading />}
      {!!error && <Modal title={t('modals.error.title')} message={error} />}
      {!!usernameEmailSent && (
        <Modal
          title={t('modals.success.resend.title')}
          message={t('modals.success.resend.message', {
            username: usernameEmailSent,
          })}
        />
      )}
      {!!usernameStatusUpdated && (
        <Modal
          title={t('modals.success.update.title')}
          message={t('modals.success.update.message', {
            username: getCompleteName(user),
          })}
          onClickOk={refreshList}
        />
      )}
    </Box>
  );
};

interface DoctorProps {
  doctorData: DoctorData | undefined;
}

const Doctor: React.FC<DoctorProps> = ({ doctorData }) => {
  if (!doctorData) return <></>;

  const { userInfo } = doctorData;
  const user = { userInfo, userType: UserType.DOCTOR } as User;

  return (
    <Box>
      <Typography variant='body2' sx={{ opacity: 0.65 }}>
        {getCompleteName(user, true)}
      </Typography>
      <Typography variant='body2' sx={{ opacity: 0.65 }}>
        {userInfo.speciality}
      </Typography>
    </Box>
  );
};
