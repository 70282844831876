import { Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

type Props = {
  selected: boolean;
  total: string;
  text: string;
  onClick: () => void;
};
export const StatisticsCard: React.FC<Props> = ({
  selected,
  total,
  text,
  onClick,
}): JSX.Element => {
  return (
    <Card
      variant='outlined'
      sx={{
        marginRight: '-22px',
        backgroundColor: (theme: Theme) =>
          selected
            ? `${theme.palette.info.dark}`
            : theme.palette.background.default,
        borderColor: (theme: Theme) =>
          selected ? 'none' : theme.palette.info.main,
        borderWidth: selected ? '0px' : '1px',
        transform: `scale(${selected ? 1.3 : 1})`,
        borderRadius: '6px',
        height: '76px',
        width: '80%',
        outline: (theme: Theme) =>
          selected ? 'none' : `1px solid ${theme.palette.grey[500]}`,
        transition: 'all 0.25s ease-in-out',
        '&:hover': {
          cursor: selected ? '' : 'pointer',
          borderColor: (theme: Theme) =>
            selected ? 'none' : theme.palette.info.dark,
          outline: (theme: Theme) =>
            selected ? 'none' : `2px solid ${theme.palette.info.dark}`,
        },
      }}
      onClick={selected ? () => {} : onClick}
    >
      <CardContent
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1rem',
          alignItems: 'center',
          paddingBottom: '12px !important',
        }}
      >
        <Typography
          variant='h3'
          sx={{
            color: (theme: Theme) =>
              selected
                ? theme.palette.background.default
                : theme.palette.grey[300],
            textAlign: 'right',
            fontWeight: 500,
            fontSize: '2.4rem',
          }}
        >
          {total}
        </Typography>
        <Typography
          variant='body2'
          sx={{
            color: (theme: Theme) =>
              selected
                ? theme.palette.background.default
                : theme.palette.grey[300],
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'flex-end',
            fontSize: '0.7rem',
          }}
        >
          {total ? text : ''}
        </Typography>
      </CardContent>
    </Card>
  );
};
