import { useCallback, useEffect, useState } from 'react';
import {
  CONSOLE_ADMIN_PROBLEMS_CATALOG,
  useConfigLazy,
  useHelpLazy,
} from 'telemed-core';

export const useHelp = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [problemOptions, setProblemOptions] = useState([]);

  const { error: gerErr, loading: getLoading, get } = useConfigLazy();
  const { error: hErr, loading: hLoading, send, sent } = useHelpLazy();

  useEffect(() => {
    !!gerErr && setError(gerErr);
    !!hErr && setError(hErr);
  }, [gerErr, hErr]);

  useEffect(() => {
    setLoading(getLoading || hLoading);
  }, [getLoading, hLoading]);

  const getProblems = useCallback(async () => {
    const catalog = await get(CONSOLE_ADMIN_PROBLEMS_CATALOG);
    setProblemOptions(
      catalog.map(({ name }: any) => ({ value: name, label: name }))
    );
  }, [get]);

  useEffect(() => {
    getProblems();
  }, [getProblems]);

  return {
    error,
    loading,
    problemOptions,
    sent,
    send,
  };
};
