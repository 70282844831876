import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';
import { Box, CardContent, Typography } from '@material-ui/core';
import { HighchartsProvider } from 'react-jsx-highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import { makeStyles } from '@material-ui/styles';

import { ChartData, ChartType } from 'telemed-core';

import { GraphicViewerCard } from './GraphicViewerCard';
import { ChartsForm } from '../forms';

addHighchartsMore(Highcharts);
addNoDataModule(Highcharts);

type Props = {
  selectedIdx: ChartType | -1;
};

const keyPrefix = 'components.GraphicsViewer';

export const GraphicsViewer: React.FC<Props> = ({ selectedIdx }) => {
  const { t } = useTranslation('translation', { keyPrefix });

  if (selectedIdx === -1) {
    return (
      <Box
        sx={{
          minHeight: (theme: Theme) => `calc(100vh - ${theme.spacing(8)})`,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='body2' sx={{ opacity: 0.45 }}>
          {t('noGraphicsSelected')}
        </Typography>
      </Box>
    );
  }

  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <Content
        chartType={ChartType.PATIENTS}
        visible={selectedIdx === ChartType.PATIENTS}
      />
      <Content
        chartType={ChartType.DOCTORS}
        visible={selectedIdx === ChartType.DOCTORS}
      />
      <Content
        chartType={ChartType.CONSULTATIONS}
        visible={selectedIdx === ChartType.CONSULTATIONS}
      />
    </HighchartsProvider>
  );
};

type ContentProps = {
  chartType: ChartType;
  visible: boolean;
};

const Content: React.FC<ContentProps> = ({
  chartType,
  visible,
}): JSX.Element => {
  const classes = useStyles();
  const [chartsData, setChartsData] = useState<ChartData[]>([]);

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: (theme: Theme) => `${theme.palette.info.dark}`,
        border: 'none',
        display: visible ? 'grid' : 'none',
      }}
    >
      <CardContent
        sx={{
          gridTemplateRows: '86px 1fr',
        }}
      >
        <Box display='flex' justifyContent='center' alignItems='center'>
          <ChartsForm
            className={classes.form}
            chartType={chartType}
            setChartData={setChartsData}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          {chartsData.map((chartData: any, idx: number) => {
            console.log(chartsData);
            return (
              <GraphicViewerCard
                key={idx}
                chartData={chartData}
                className={
                  chartsData.length === 2
                    ? classes.fullItem
                    : chartsData.length === 3 && idx === 2
                    ? classes.fullItem
                    : ''
                }
              />
            );
          })}
        </Box>
      </CardContent>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '770px',
    display: 'grid',
    gridTemplateColumns: '130px 180px 180px 200px',
    justifyItems: 'center',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    '& p': {
      color: theme.palette.info.main,
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
    '& .Mui-error': {
      color: theme.palette.info.main,
    },
  },
  fullItem: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
}));
