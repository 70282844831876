import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { ChartType, Loading, Modal, useStatistics } from 'telemed-core';

import { StatisticsCard } from './StatisticsCard';
import { GraphicsViewer } from './GraphicsViewer';
import { Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const keyPrefix = 'components.Statistics';

export const Statistics: React.FC = (): JSX.Element => {
  const [selectedIdx, setSelectedIdx] = useState<ChartType | -1>(-1);
  const { t } = useTranslation('translation', { keyPrefix });
  const { consultations, doctors, error, loading, patients, get } =
    useStatistics();

  useEffect(() => {
    get();
  }, [get]);

  const tabs = [
    {
      total: patients,
      text: t('patients'),
      onClick: () => {
        setSelectedIdx(ChartType.PATIENTS);
      },
    },
    {
      total: doctors,
      text: t('doctors'),
      onClick: () => {
        setSelectedIdx(ChartType.DOCTORS);
      },
    },
    {
      total: consultations,
      text: t('consultations'),
      onClick: () => {
        setSelectedIdx(ChartType.CONSULTATIONS);
      },
    },
  ];

  return (
    <>
      <Box
        display='grid'
        gridTemplateColumns='200px 1fr'
        sx={{
          width: '100%',
          minHeight: (theme: Theme) => `calc(100vh - ${theme.spacing(8)})`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '1.5rem',
          }}
        >
          {tabs.map((props, idx) => (
            <StatisticsCard
              key={idx}
              {...props}
              selected={idx + 1 === selectedIdx}
            />
          ))}
        </Box>
        <Box>
          <GraphicsViewer selectedIdx={selectedIdx} />
        </Box>
      </Box>
      {loading && <Loading />}
      {error != null && <Modal title='Error' message={error} />}
    </>
  );
};
