import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  AddButton,
  Filter,
  Layout,
  useUsersLazy,
  User,
  UserType,
  getCompleteName,
} from 'telemed-core';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';

import { PatientListItem } from '../../components';

const keyPrefix = 'container.PatientListContainer';

export const PatientListContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading, getByType } = useUsersLazy();
  const [list, setList] = useState<User[]>([]);
  const [filteredList, setFilteredList] = useState<User[]>([]);
  const navigate = useNavigate();

  const getPatients = useCallback(
    async function () {
      const patients = await getByType(UserType.PATIENT);
      setList(patients);
      setFilteredList(patients);
    },
    [getByType]
  );

  useEffect(() => {
    getPatients();
  }, [getByType, getPatients]);

  const add = useCallback(() => {
    navigate('/patient', { state: { user: null } });
  }, [navigate]);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.trim() === '') {
      setFilteredList(list);
      return;
    }

    const newFilteredList = list.filter((user) => {
      const name = getCompleteName(user).replaceAll(' ', '').toLowerCase();
      const clearValue = value.replaceAll(' ', '').toLowerCase();
      return name.includes(clearValue);
    });

    console.log('newFilteredList', newFilteredList);

    setFilteredList(newFilteredList);
  };

  const handleRefreshList = async () => {
    let newFilteredList = await getByType(UserType.PATIENT);

    if (filteredList.length > 0) {
      newFilteredList = filteredList.map(({ id }) => {
        return newFilteredList.find((patient: User) => patient.id === id);
      });
    }

    setFilteredList(newFilteredList);
  };

  return (
    <Layout title={t('title')} error={error} loading={loading}>
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: (theme) => theme.spacing(5),
          marginBottom: (theme) => theme.spacing(3),
        }}
      >
        <Box width='60%'>
          <Filter
            filterText={t('filter.text')}
            placeholder={t('filter.placeholder')}
            onChange={handleChange}
          />
        </Box>
        <AddButton label={t('addButton.label')} onClick={add} />
      </Container>
      <Container maxWidth='md'>
        <List>
          {filteredList.map((user: User, idx: number) => (
            <PatientListItem
              key={idx}
              user={user}
              t={t}
              refreshList={handleRefreshList}
            />
          ))}
        </List>
      </Container>
    </Layout>
  );
};
