import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, BasicTextInput, Modal, Loading } from 'telemed-core';

import { useLoginFormManagement } from '../hooks';

const keyPrefix = 'forms.LoginForm';

type props = React.HTMLAttributes<HTMLDivElement>;

export const LoginForm: React.FC<props> = ({ className }): JSX.Element => {
  const { error, loading, methods, submit } = useLoginFormManagement();
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <BasicTextInput
          name='username'
          type='text'
          errorColorText='white'
          placeholder={t('username.placeholder')}
          rounded
        />
        <BasicTextInput
          name='password'
          type='password'
          errorColorText='white'
          placeholder={t('password.placeholder')}
          rounded
        />
        <Button buttonProps={{ type: 'submit' }} rounded>
          {t('button.label')}
        </Button>
      </form>
      {loading && <Loading />}
      {error != null && <Modal title={t('error.title')} message={error} />}
    </FormProvider>
  );
};
