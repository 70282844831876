import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import { Equipment, Layout } from 'telemed-core';
import { EquipmentForm } from '../../forms';

const keyPrefix = 'container.EquipmentContainer';

export const EquipmentContainer: React.FC<RouteComponentProps> = ({
  location,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { equipment } = location?.state as { equipment: Equipment };

  return (
    <Layout title={t(`title.${!equipment ? 'new' : 'add'}`)}>
      <Container
        maxWidth='md'
        sx={{
          marginTop: (theme) => theme.spacing(5),
        }}
      >
        <EquipmentForm equipment={equipment} />
      </Container>
    </Layout>
  );
};
