import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import { Content, Layout } from 'telemed-core';
import { ContentForm } from '../../forms';

const keyPrefix = 'container.ContentContainer';

export const ContentContainer: React.FC<RouteComponentProps> = ({
  location,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { content } = location?.state as { content: Content };

  return (
    <Layout title={t(`title.${!content ? 'new' : 'add'}`)}>
      <Container maxWidth='md' sx={{ marginTop: (theme) => theme.spacing(5) }}>
        <ContentForm content={content} />
      </Container>
    </Layout>
  );
};
