import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as yup from 'yup';
import { ChartType, ISelectItem, useChartsLazy } from 'telemed-core';
import { useEffect } from 'react';

const dateFormat = 'YYYY-MM-DD';
const keyPrefix = 'general';

export interface ChartFormFields {
  startDate: string;
  endDate: string;
  rowNumber: ISelectItem;
}

const defaultValues = {
  startDate: moment().subtract(1, 'month').format(dateFormat),
  endDate: moment().format(dateFormat),
  rowNumber: { value: '5', label: '5' },
};

export const useChartsFormManagement = (chartType: ChartType) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { chartData, error, loading, fetch } = useChartsLazy();

  const schema = yup.object().shape({
    startDate: yup.date().required(t('required')).typeError(t('date.format')),
    endDate: yup.date().required(t('required')).typeError(t('date.format')),
    rowNumber: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
  });

  const methods = useForm<ChartFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const submit: SubmitHandler<ChartFormFields> = async ({
    startDate,
    endDate,
    rowNumber,
  }) => {
    await fetch(
      chartType,
      moment(startDate).format(dateFormat),
      moment(endDate).format(dateFormat),
      parseInt(rowNumber.value)
    );
  };

  useEffect(() => {
    fetch(
      chartType,
      defaultValues.startDate,
      defaultValues.endDate,
      parseInt(defaultValues.rowNumber.value)
    );
  }, [fetch, chartType]);

  return { chartData, error, loading, methods, submit };
};
