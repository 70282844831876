import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import {
  useEmailResend,
  usePatientStatus,
  User,
  UserStatus,
} from 'telemed-core';

export const usePatientListItem = (user: User) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    error: erErr,
    loading: erLoading,
    requestError: errErr,
    username: usernameEmailSent,
    send,
  } = useEmailResend();

  const {
    error: psErr,
    loading: psLoading,
    requestError: psrErr,
    username: usernameStatusUpdated,
    update,
    status,
    statusDescription,
  } = usePatientStatus(user);

  useEffect(() => {
    !!erErr && setError(erErr);
    !!errErr && setError(errErr.message);
    !!psErr && setError(psErr);
    !!psrErr && setError(psrErr.message);
  }, [erErr, errErr, psErr, psrErr]);

  useEffect(() => {
    setLoading(erLoading || psLoading);
  }, [erLoading, psLoading]);

  const edit = useCallback(() => {
    navigate('/patient', { state: { user } });
  }, [navigate, user]);

  const resend = useCallback(() => {
    send(user.username!);
  }, [send, user]);

  const updateStatus = useCallback(
    async (status: UserStatus, statusDescription?: string) => {
      await update(user, status, statusDescription);
    },
    [update, user]
  );

  return {
    error,
    loading,
    usernameEmailSent,
    usernameStatusUpdated,
    status,
    statusDescription,
    edit,
    resend,
    updateStatus,
  };
};
