import React from 'react';
import Div100vh from 'react-div-100vh';
import { Trans } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import { LogoTypeSvg } from 'telemed-core';

import { LoginForm } from '../forms';

const keyPrefix = 'container.LoginContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoContainer: {
    width: theme.spacing(45),
    margin: `0 auto ${theme.spacing(4)} auto`,
  },
  form: {
    display: 'grid',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    justifyItems: 'center',
    rowGap: theme.spacing(3),
    '& button': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const LoginContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const classes = useStyles();

    return (
      <Div100vh className={classes.root}>
        <Container maxWidth='xs'>
          <Box>
            <LogoTypeSvg />
          </Box>
          <Typography component='div'>
            <Trans
              i18nKey={`${keyPrefix}.headingText`}
              components={[<strong></strong>]}
            />
          </Typography>
          <LoginForm className={classes.form} />
        </Container>
      </Div100vh>
    );
  };
