import Grid from '@material-ui/core/Grid';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  BasicTextInput,
  RadioGroupInput,
  Loading,
  Modal,
} from 'telemed-core';

import { useHelp, useHelpFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const keyPrefix = 'forms.HelpForm';

export const HelpForm: React.FC<Props> = ({ className }): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading, problemOptions, sent, send } = useHelp();
  const { methods, submit } = useHelpFormManagement(send);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)} className={className}>
          <Grid
            container
            spacing={4}
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Grid item md={4}>
              <RadioGroupInput name='problemName' options={problemOptions} />
            </Grid>
            <Grid item md={8}>
              <BasicTextInput
                name='problemDescription'
                maxLength={50}
                minRows={8}
                label={t('problemDescription.label')}
                placeholder={t('problemDescription.placeholder')}
              />
            </Grid>
            <Grid
              item
              md={12}
              display='flex'
              justifyContent='center'
              marginTop={4}
            >
              <Button buttonProps={{ type: 'submit' }} gray>
                {t('submit.label')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('modals.error.title')} message={error} />}
      {!!sent && (
        <Modal
          title={t('modals.success.title')}
          message={t('modals.success.message')}
        />
      )}
    </>
  );
};
