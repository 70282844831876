import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {
  AddButton,
  Content,
  Layout,
  useContentsLazy,
  MediaType,
  Filter,
} from 'telemed-core';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';

import { ContentCardItem } from '../../components';

const keyPrefix = 'container.ContentListContainer';

export const ContentListContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix });
    const { error, loading, get } = useContentsLazy();
    const [value, setValue] = React.useState('1');
    const [list, setList] = useState<Content[]>([]);
    const [filteredList, setFilteredList] = useState<Content[]>([]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const getContents = useCallback(
      async function () {
        const contentList = await get();
        setList(contentList);
        setFilteredList(contentList);
      },
      [get]
    );

    useEffect(() => {
      getContents();
    }, [getContents, get]);

    const add = useCallback(() => {
      navigate('/content', { state: { content: null } });
    }, [navigate]);

    const listByType = (type: MediaType) =>
      filteredList.filter(
        ({ contentInfo }) => contentInfo.media.mediaType === type
      );

    const handleFilterChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (value.trim() === '') {
        setFilteredList(list);
        return;
      }

      const filteredList = list.filter(({ name: contentName }) => {
        const name = contentName.replaceAll(' ', '').toLowerCase();
        const clearValue = value.replaceAll(' ', '').toLowerCase();
        return name.includes(clearValue);
      });

      setFilteredList(filteredList);
    };

    return (
      <Layout title={t('title')} error={error} loading={loading}>
        <Container
          maxWidth='md'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: (theme) => theme.spacing(5),
          }}
        >
          <Box width='60%'>
            <Filter
              filterText={t('filter.text')}
              placeholder={t('filter.placeholder')}
              onChange={handleFilterChange}
            />
          </Box>
          <AddButton label={t('addButton.label')} onClick={add} />
        </Container>
        <Container maxWidth='md'>
          <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  sx={{
                    alignItems: 'center',
                    margin: `0 auto`,
                    '& .MuiTabs-flexContainer': {
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Tab
                    label={t('tabs.images.label')}
                    value='1'
                    sx={{
                      width: '100%',
                      fontFamily: 'AntennaCondBeta',
                      fontSize: '1.1rem',
                    }}
                  />
                  <Tab
                    label={t('tabs.videos.label')}
                    value='2'
                    sx={{
                      width: '100%',
                      fontFamily: 'AntennaCondBeta',
                      fontSize: '1.1rem',
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <List
                  sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 250px)',
                    gap: (theme) => theme.spacing(3),
                  }}
                >
                  {listByType(MediaType.IMAGE).map(
                    (content: Content, key: number) => (
                      <ContentCardItem key={key} {...content} />
                    )
                  )}
                </List>
              </TabPanel>
              <TabPanel value='2'>
                <List
                  sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 375px)',
                    gap: (theme) => theme.spacing(3),
                  }}
                >
                  {listByType(MediaType.VIDEO).map(
                    (content: Content, key: number) => (
                      <ContentCardItem key={key} {...content} />
                    )
                  )}
                </List>
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Layout>
    );
  };
