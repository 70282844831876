import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Content,
  useContentLazy,
  USE_CONTENT_MODE,
  MediaType,
  ValidateFileUploadOptions,
} from 'telemed-core';

const keyPrefix = 'general';

export interface ContentFormFields {
  fileName: string;
  name: string;
  description: string;
}

export const useContentFormManagement = (content?: Content) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { axn, error, loading, successSubmit } = useContentLazy(
    !!content ? USE_CONTENT_MODE.UPDATE : USE_CONTENT_MODE.ADD
  );

  const defaultValues = {
    fileName: content?.contentInfo.media.url || '',
    name: content?.name || '',
    description: content?.description || '',
  };

  const schema = yup.object().shape({
    fileName: yup.string().required(t('ContentImg.required')),
    name: yup.string().required(t('required')),
    description: yup.string().required(t('required')),
  });

  const methods = useForm<ContentFormFields>({
    resolver: yupResolver(schema),
    defaultValues: !!content ? defaultValues : undefined,
  });

  type SubmitProps = {
    fields: ContentFormFields;
    file: File | null;
    videoThumbnail: File | null;
    validateFileUploadOptions: ValidateFileUploadOptions;
  };

  const submit: SubmitHandler<SubmitProps> = async ({
    fields: { name, description },
    file,
    videoThumbnail,
    validateFileUploadOptions,
  }) => {
    const modifiedContent = {
      ...(!!content ? { id: content.id } : {}),
      name,
      description,
      media: {
        ...content?.contentInfo.media,
        ...(!!file
          ? {
              mediaType: file.type.includes('video')
                ? MediaType.VIDEO
                : MediaType.IMAGE,
            }
          : {}),
      },
    } as Content;

    await axn({
      content: modifiedContent,
      file,
      videoThumbnail,
      validateFileUploadOptions,
    });
  };

  return {
    error,
    loading,
    methods,
    successSubmit,
    submit,
  };
};
