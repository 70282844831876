import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UserStatus } from 'telemed-core';

const keyPrefix = 'general';

export interface PatientStatusFormFields {
  unsubscribeType: string | null;
  statusDescription: string | null;
}

export const usePatientStatusFormManagement = (
  updateStatus: (
    status: UserStatus,
    statusDescription?: string | undefined
  ) => Promise<void>,
  onBack: () => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const defaultValues = {
    unsubscribeType: null,
    statusDescription: null,
  };

  const schema = yup.object().shape({
    unsubscribeType: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
    statusDescription: yup
      .string()
      .typeError(t('required'))
      .required(t('required')),
  });

  const methods = useForm<PatientStatusFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const submit: SubmitHandler<PatientStatusFormFields> = async ({
    unsubscribeType,
    statusDescription,
  }) => {
    await updateStatus(
      parseInt(unsubscribeType!),
      statusDescription || undefined
    );
    onBack();
  };

  return { methods, submit };
};
