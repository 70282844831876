import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ChartData,
  ChartType,
  DateInput,
  Loading,
  Modal,
  RowNumberInput,
} from 'telemed-core';

import { useChartsFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  chartType: ChartType;
  setChartData: (chartData: ChartData[]) => void;
}

const keyPrefix = 'forms.ChartsForm';

export const ChartsForm: React.FC<Props> = ({
  className,
  chartType,
  setChartData,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { chartData, error, loading, methods, submit } =
    useChartsFormManagement(chartType);

  useEffect(() => {
    setChartData(chartData);
  }, [chartData, setChartData]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)} className={className}>
          <RowNumberInput label={t('rowNumber.label')} />
          <DateInput
            name='startDate'
            openTo='day'
            label={t('startDate.label')}
            placeholder={t('startDate.placeholder')}
          />
          <DateInput
            name='endDate'
            openTo='day'
            label={t('endDate.label')}
            placeholder={t('endDate.placeholder')}
          />
          <Button buttonProps={{ type: 'submit' }} gray>
            {t('submit.label')}
          </Button>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('modals.error.title')} message={error} />}
    </>
  );
};
