import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  AddButton,
  getCompleteName,
  Equipment,
  Image,
  ImageFrame,
  InventoryStatus,
  Layout,
  Modal,
  useEquipmentsLazy,
  User,
  EditSvg,
  Separator,
  Filter,
} from 'telemed-core';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import Stack from '@material-ui/core/Stack';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

const keyPrefix = 'container.EquipmentListContainer';

export const EquipmentListContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const { t } = useTranslation('translation', { keyPrefix });
    const { error, loading, get, remove, unassign } = useEquipmentsLazy();
    const [searching, setSearching] = useState<string>('');
    const [list, setList] = useState<Equipment[]>([]);
    const [filteredList, setFilteredList] = useState<Equipment[]>([]);
    const [unassignSucceeded, setUnassignSucceeded] = useState<boolean>(false);
    const [removeSucceeded, setRemoveSucceeded] = useState<boolean>(false);
    const [equipmentToRemove, setEquipmentToRemove] =
      useState<Equipment | null>(null);
    const navigate = useNavigate();

    const getEquipments = async () => {
      const equipmentList = await get();
      const noDeletedEquipments = equipmentList.filter(
        ({ inventory }) => inventory.status !== InventoryStatus.DELETED
      );
      setList(noDeletedEquipments);
      searching === ''
        ? setFilteredList(noDeletedEquipments)
        : doFilterList(noDeletedEquipments);
    };

    useEffect(() => {
      getEquipments();
    }, [get]);

    const add = useCallback(() => {
      navigate('/equipment', { state: { equipment: null } });
    }, [navigate]);

    const edit = useCallback(
      (equipment: Equipment) => {
        navigate('/equipment', { state: { equipment } });
      },
      [navigate]
    );

    const handleUnassignUser = async (
      equipmentId: number,
      patientId: number
    ) => {
      setUnassignSucceeded(false);

      const res = await unassign(equipmentId, patientId);
      if (res) {
        getEquipments();
        setUnassignSucceeded(true);
      }
    };

    const handleRemoveEquipment = async () => {
      if (!equipmentToRemove || !equipmentToRemove.inventory.id) return;

      const res = await remove(equipmentToRemove.inventory.id);
      setEquipmentToRemove(null);

      if (res) {
        await getEquipments();
        setRemoveSucceeded(true);
      }
    };

    const doFilterList = (newList?: Equipment[] | [] | undefined) => {
      if (searching === '') {
        setFilteredList(list);
        return;
      }

      const filteredList = (!!newList ? newList : list).filter(
        ({ inventory }) => {
          const name = inventory.name.replaceAll(' ', '').toLowerCase();
          const clearValue = searching.replaceAll(' ', '').toLowerCase();
          return name.includes(clearValue);
        }
      );

      setFilteredList(filteredList);
    };

    const handleChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setSearching(value);
      doFilterList();
    };

    return (
      <Layout title={t('title')} error={error} loading={loading}>
        <Container
          maxWidth='md'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: (theme) => theme.spacing(5),
          }}
        >
          <Box width='60%'>
            <Filter
              filterText={t('filter.text')}
              placeholder={t('filter.placeholder')}
              onChange={handleChange}
            />
          </Box>
          <AddButton label={t('addButton.label')} onClick={add} />
        </Container>
        <Container maxWidth='md'>
          <List>
            {filteredList.map((equipment: Equipment, idx: number) => {
              const { inventory, user } = equipment;
              const {
                id: equipmentId,
                brand,
                creationDate,
                description,
                inventoryInfo: { media },
                model,
                name,
                serie,
                status,
              } = inventory;

              const { id: patientId } = user;

              return (
                <Box key={idx}>
                  <ListItem
                    sx={{
                      padding: (theme) => `${theme.spacing(3)} 0`,
                      display: 'grid',
                      gridTemplateColumns: '5px 180px 1fr 5px',
                      columnGap: (theme) => theme.spacing(2),
                    }}
                  >
                    <div></div>
                    <ImageFrame size={180}>
                      <Image url={media.url || ''} size={179} />
                    </ImageFrame>
                    <Box
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                      }}
                    >
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        sx={{ width: '100%' }}
                      >
                        <Box>
                          <Stack
                            direction='row'
                            spacing={1}
                            marginBottom={1}
                            divider={
                              <Divider orientation='vertical' flexItem />
                            }
                          >
                            <Typography variant='caption' color='primary'>
                              {name}
                            </Typography>
                            <Typography variant='body2' color='primary'>
                              {brand}
                            </Typography>
                          </Stack>
                          <Stack
                            direction='column'
                            spacing={1}
                            columnGap={2}
                            marginBottom={2}
                          >
                            <Typography variant='caption'>{model}</Typography>
                            <Typography variant='body2' sx={{ marginTop: 0 }}>
                              {serie}
                            </Typography>
                          </Stack>
                        </Box>
                        <Stack
                          direction='row'
                          alignItems='center'
                          alignContent='center'
                          columnGap={2}
                        >
                          {status === InventoryStatus.ASSIGNED && (
                            <Button
                              variant='text'
                              color='error'
                              sx={{ paddingTop: 1.7 }}
                              onClick={() => {
                                setEquipmentToRemove(equipment);
                              }}
                            >
                              {t('removeButton.label')}
                            </Button>
                          )}
                          <IconButton
                            size='large'
                            color='primary'
                            onClick={() => {
                              edit(equipment);
                            }}
                          >
                            <EditSvg width={24} />
                          </IconButton>
                        </Stack>
                      </Stack>
                      <Typography
                        variant='body2'
                        color='grey.300'
                        sx={{ opacity: 0.7 }}
                      >
                        {description}
                      </Typography>
                      <Stack
                        sx={{ width: '100%' }}
                        marginTop={2}
                        justifyContent='space-between'
                        alignItems='flex-end'
                        direction='row'
                      >
                        <Typography
                          variant='body2'
                          color='grey.300'
                          sx={{
                            opacity: 0.7,
                          }}
                        >
                          {t('creation')}
                          <Moment fromNow locale='es-mx'>
                            {creationDate}
                          </Moment>
                        </Typography>
                        {user.id > 0 ? (
                          <Chip
                            color='info'
                            variant='filled'
                            label={getCompleteName(user as User)}
                            onDelete={() => {
                              if (!equipmentId) return;
                              handleUnassignUser(equipmentId, patientId);
                            }}
                          />
                        ) : (
                          <Typography
                            variant='body2'
                            color='GrayText'
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            {t('empty')}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                    <div></div>
                  </ListItem>
                  <Separator />
                </Box>
              );
            })}
          </List>
        </Container>
        {equipmentToRemove && (
          <Modal
            title={t('modals.toRemove.title')}
            message={t('modals.toRemove.message', {
              name: equipmentToRemove.inventory.name,
            })}
            onClickOk={handleRemoveEquipment}
            onClickCancel={() => {
              setEquipmentToRemove(null);
            }}
          />
        )}
        {unassignSucceeded && (
          <Modal
            title={t('modals.unassignSucceeded.title')}
            message={t('modals.unassignSucceeded.message')}
            onClickOk={() => {
              setUnassignSucceeded(false);
            }}
          />
        )}
        {removeSucceeded && (
          <Modal
            title={t('modals.removeSucceeded.title')}
            message={t('modals.removeSucceeded.message')}
            onClickOk={() => {
              setEquipmentToRemove(null);
              setRemoveSucceeded(false);
            }}
          />
        )}
      </Layout>
    );
  };
