import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { Layout } from 'telemed-core';

import { Statistics } from '../components';
import { Box } from '@material-ui/core';

const keyPrefix = 'container.DashboardContainer';

export const DashboardContainer: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const { t } = useTranslation('translation', { keyPrefix });

    return (
      <Layout title={t('title')}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Statistics />
        </Box>
      </Layout>
    );
  };
