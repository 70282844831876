import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  useDoctorLazy,
  parseGenderValue,
  parseMaritalStatusValue,
  parseSpecialtyValue,
  User,
  ISelectItem,
  USE_DOCTOR_MODE,
  Gender,
  MediaType,
  UserInfo,
  ValidateFileUploadOptions,
} from 'telemed-core';
import moment from 'moment';

const keyPrefix = 'general';

export interface DoctorFormFields {
  name: string;
  firstLastName: string;
  secondLastName: string;
  gender: ISelectItem | null;
  maritalStatus: ISelectItem | null;
  date: string | null;
  bloodType: string;
  email: string;
  cellPhone: string;
  street: string;
  extNumber: string;
  intNumber?: string;
  cp: string;
  state: string;
  delegation: string;
  colony: string | ISelectItem | null;
  professionalLicense: string;
  speciality: ISelectItem | null;
  fileName: string;
}

export const useDoctorFormManagement = (user?: User) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { axn, error, loading, processedUsername } = useDoctorLazy(
    !!user ? USE_DOCTOR_MODE.UPDATE : USE_DOCTOR_MODE.ADD
  );

  const defaultValues = {
    name: user?.userInfo.name || '',
    firstLastName: user?.userInfo.firstLastName || '',
    secondLastName: user?.userInfo.secondLastName || '',
    gender: parseGenderValue(user?.userInfo.gender),
    maritalStatus: parseMaritalStatusValue(user?.userInfo.maritalStatus),
    date: user?.userInfo.birthday,
    bloodType: user?.userInfo.bloodType || '',
    email: user?.username || '',
    cellPhone: user?.userInfo.cellPhone || '',
    street: user?.userInfo.address.street || '',
    extNumber: user?.userInfo.address.extNumber || '',
    intNumber: user?.userInfo.address.intNumber || '',
    cp: user?.userInfo.address.cp || '',
    state: user?.userInfo.address.state || '',
    delegation: user?.userInfo.address.delegation || '',
    colony: user?.userInfo.address.colony || '',
    professionalLicense: user?.userInfo.professionalLicense || '',
    speciality: parseSpecialtyValue(user?.userInfo.speciality),
    fileName: user?.userInfo.specialityCertificateMedia?.name || '',
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('required')),
    firstLastName: yup.string().required(t('required')),
    secondLastName: yup.string().required(t('required')),
    gender: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    maritalStatus: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    date: yup
      .date()
      .required(t('required'))
      .max(new Date(), t('date.invalid'))
      .typeError(t('date.format')),
    bloodType: yup.string().required(t('required')),
    email: yup.string().email(t('email.format')).required(t('required')),
    cellPhone: yup.string().required(t('required')).min(10, t('phone.length')),
    street: yup.string().required(t('required')),
    extNumber: yup.string().required(t('required')),
    cp: yup.string().required(t('required')).min(5, t('cp.length')),
    state: yup.string().required(t('required')),
    delegation: yup.string().required(t('required')),
    colony: yup.lazy((value) =>
      typeof value === 'string'
        ? yup.string().required(t('required'))
        : yup.object().shape({
            value: yup.string().required(t('required')),
          })
    ),
    professionalLicense: yup.string().required(t('required')),
    speciality: yup.lazy((value) =>
      typeof value === 'string'
        ? yup.string().required(t('required'))
        : yup.object().shape({
            value: yup.string().required(t('required')),
          })
    ),
    fileName: yup.string().required(t('cert.required')),
  });

  const methods = useForm<DoctorFormFields>({
    resolver: yupResolver(schema),
    defaultValues: !!user ? defaultValues : undefined,
  });

  type SubmitProps = {
    fields: DoctorFormFields;
    certificate: File | null;
    validateFileUploadOptions: ValidateFileUploadOptions;
  };

  const submit: SubmitHandler<SubmitProps> = async ({
    fields,
    certificate,
    validateFileUploadOptions,
  }) => {
    console.log('fields', fields);
    console.log('certificate', certificate);

    const userInfo = {
      name: fields.name,
      firstLastName: fields.firstLastName,
      secondLastName: fields.secondLastName,
      gender:
        fields.gender?.value === Gender.MASCULINE
          ? Gender.MASCULINE
          : Gender.FEMALE,
      maritalStatus: fields.maritalStatus?.value || '',
      birthday: moment(fields.date).format('YYYY-MM-DD'),
      bloodType: fields.bloodType,
      cellPhone: fields.cellPhone,
      address: {
        street: fields.street,
        extNumber: fields.extNumber,
        intNumber: fields.intNumber,
        cp: fields.cp,
        state: fields.state,
        delegation: fields.delegation,
        colony:
          (typeof fields.colony == 'string'
            ? fields.colony
            : fields.colony?.value) || '',
      },
      professionalLicense: fields.professionalLicense,
      speciality:
        typeof fields.speciality == 'string'
          ? fields.speciality
          : fields.speciality?.value,
      specialityCertificateMedia: {
        ...user?.userInfo.specialityCertificateMedia,
        mediaType:
          !!certificate && certificate.type.includes('pdf')
            ? MediaType.PDF
            : MediaType.IMAGE,
      },

      // profileImage: {
      //   mediaType: MediaType.IMAGE,
      // },
    } as UserInfo;

    await axn({
      username: fields.email,
      userInfo,
      userId: user?.id,
      certificate,
      validateFileUploadOptions,
    });
  };

  return {
    error,
    loading,
    methods,
    processedUsername,
    submit,
  };
};
