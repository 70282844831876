import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import {
  InputLabel,
  Layout,
  User,
  UserListItem,
  UserType,
  useUsersLazy,
} from 'telemed-core';
import { DoctorForm } from '../../forms';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const keyPrefix = 'container.DoctorContainer';

export const DoctorContainer: React.FC<RouteComponentProps> = ({
  location,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { user } = location?.state as { user: User };

  return (
    <Layout title={t(`title.${!user ? 'new' : 'add'}`)}>
      <Container
        maxWidth={`${!!user ? 'lg' : 'md'}`}
        sx={{
          display: 'grid',
          gridTemplateColumns: `1fr ${!!user ? ' 312px ' : ''}`,
          columnGap: (theme) => theme.spacing(2),
          marginTop: (theme) => theme.spacing(5),
        }}
      >
        <DoctorForm user={user} />
        {!!user && (
          <AssignedPatientList
            label={t('patientList.label')}
            emptyText={t('patientList.emptyText')}
            doctorId={user.id}
          />
        )}
      </Container>
    </Layout>
  );
};

type PropsAssignedPatientList = {
  doctorId: number;
  emptyText: string;
  label: string;
};

const AssignedPatientList: React.FC<PropsAssignedPatientList> = ({
  doctorId,
  emptyText,
  label,
}) => {
  const [list, setList] = useState<User[]>([]);
  const { error, loading, getByType } = useUsersLazy();

  const getPatients = useCallback(
    async function () {
      const patients = await getByType(UserType.PATIENT);
      const assignedPatients = patients.filter(
        (patient: User) => patient.doctorData?.id === doctorId
      );
      setList(assignedPatients);
    },
    [getByType]
  );

  useEffect(() => {
    getPatients();
  }, [getPatients, getByType]);

  return (
    <Box>
      <InputLabel label={label} />
      <Box
        sx={{
          height: 580,
          maxHeight: 600,
          border: (theme) => `1px solid ${theme.palette.grey[100]}`,
          overflowY: 'scroll',
          marginTop: (theme) => theme.spacing(0.35),
        }}
      >
        {list.length < 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {loading ? (
              <CircularProgress size={42} sx={{ opacity: 0.7 }} />
            ) : (
              <Typography variant='body2' color='GrayText'>
                {error ? error : emptyText}
              </Typography>
            )}
          </Box>
        )}
        <List
          sx={{
            transform: 'scale(0.8)',
            transformOrigin: 'top left',
          }}
        >
          {list.map((user: User, idx: number) => {
            return <UserListItem key={idx} user={user} />;
          })}
        </List>
      </Box>
    </Box>
  );
};
