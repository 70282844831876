import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  useEquipmentLazy,
  Equipment,
  USE_EQUIPMENT_MODE,
  MediaType,
  ValidateFileUploadOptions,
  Inventory,
} from 'telemed-core';

const keyPrefix = 'general';

export interface EquipmentFormFields {
  fileName: string;
  name: string;
  brand: string;
  model: string;
  serie: string;
  description: string;
}

export const useEquipmentFormManagement = (equipment?: Equipment) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { axn, error, loading, successSubmit } = useEquipmentLazy(
    !!equipment ? USE_EQUIPMENT_MODE.UPDATE : USE_EQUIPMENT_MODE.ADD
  );

  const defaultValues = {
    fileName: equipment?.inventory.inventoryInfo.media.url || '',
    name: equipment?.inventory.name || '',
    brand: equipment?.inventory.brand || '',
    model: equipment?.inventory.model || '',
    serie: equipment?.inventory.serie || '',
    description: equipment?.inventory.description || '',
  };

  const schema = yup.object().shape({
    fileName: yup.string().required(t('equipmentImg.required')),
    name: yup.string().required(t('required')),
    brand: yup.string().required(t('required')),
    model: yup.string().required(t('required')),
    serie: yup.string().required(t('required')),
    description: yup.string().required(t('required')),
  });

  const methods = useForm<EquipmentFormFields>({
    resolver: yupResolver(schema),
    defaultValues: !!equipment ? defaultValues : undefined,
  });

  type SubmitProps = {
    fields: EquipmentFormFields;
    photo: File | null;
    validateFileUploadOptions: ValidateFileUploadOptions;
  };

  const submit: SubmitHandler<SubmitProps> = async ({
    fields: { name, brand, model, description, serie },
    photo,
    validateFileUploadOptions,
  }) => {
    const inventory = {
      // ...(!!equipment ? { ...equipment.inventory } : {}),
      ...(!!equipment
        ? { id: equipment.inventory.id, status: equipment.inventory.status }
        : {}),
      name,
      brand,
      model,
      serie,
      description,
      media: {
        ...equipment?.inventory.inventoryInfo.media,
        mediaType: MediaType.IMAGE,
      },
    } as Inventory;

    await axn({
      inventory,
      photo,
      validateFileUploadOptions,
    });
  };

  return {
    error,
    loading,
    methods,
    successSubmit,
    submit,
  };
};
