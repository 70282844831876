import React from 'react';
import { Redirect, Router } from '@reach/router';
import { LayoutContainer, useTelemed, MenuOption } from 'telemed-core';

import {
  LoginContainer,
  PatientListContainer,
  PatientContainer,
  DoctorListContainer,
  DoctorContainer,
  EquipmentListContainer,
  EquipmentContainer,
  ContentListContainer,
  ContentContainer,
  HelpContainer,
  DashboardContainer,
} from '../containers';

const Routes: React.FC = () => {
  const { isAuth } = useTelemed();

  if (!isAuth) {
    return (
      <Router>
        <LoginContainer path='/*' />
      </Router>
    );
  }

  return (
    <Router>
      <LayoutContainer path='/'>
        <Redirect from='/' to='dashboard' noThrow />
        <DashboardContainer path='dashboard' />
        <PatientListContainer path='patient/list' />
        <PatientContainer path='patient' />
        <DoctorListContainer path='doctor/list' />
        <DoctorContainer path='doctor' />
        <EquipmentListContainer path='equipment/list' />
        <EquipmentContainer path='equipment' />
        <ContentListContainer path='content/list' />
        <ContentContainer path='content' />
        <HelpContainer path='help' />
      </LayoutContainer>
    </Router>
  );
};

export default Routes;

export const menuOptions: MenuOption[] = [
  { label: 'Dashboard', href: '/dashboard' },
  { label: 'Pacientes', href: '/patient/list' },
  { label: 'Doctores', href: '/doctor/list' },
  { label: 'Inventario', href: '/equipment/list' },
  { label: 'Biblioteca de Medios', href: '/content/list' },
  { label: 'Soporte y Ayuda', href: '/help' },
];
