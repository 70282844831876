import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  usePatientLazy,
  parseGenderValue,
  parseMaritalStatusValue,
  User,
  ISelectItem,
  getCompleteName,
  UserType,
  USE_PATIENT_MODE,
  Gender,
  MediaType,
  UserInfo,
} from 'telemed-core';
import moment from 'moment';

const keyPrefix = 'general';

interface FormFields {
  name: string;
  firstLastName: string;
  secondLastName: string;
  bloodType: string;
  gender: ISelectItem | null;
  maritalStatus: ISelectItem | null;
  date: string | null;
  email: string;
  cellPhone: string;
  phone: string;
  street: string;
  extNumber: string;
  intNumber?: string;
  colony: string | ISelectItem | null;
  cp: string;
  delegation: string;
  state: string;
  insurerName: string;
  policyNumber: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactPhone: string;
  doctor: ISelectItem | undefined;
  currentOccupation: string;
  previousOccupation: string;
}

export const usePatientFormManagement = (user?: User) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { axn, error, loading, processedUsername } = usePatientLazy(
    !!user ? USE_PATIENT_MODE.UPDATE : USE_PATIENT_MODE.ADD
  );

  const defaultValues = {
    name: user?.userInfo.name || '',
    firstLastName: user?.userInfo.firstLastName || '',
    secondLastName: user?.userInfo.secondLastName || '',
    bloodType: user?.userInfo.bloodType || '',
    gender: parseGenderValue(user?.userInfo.gender),
    maritalStatus: parseMaritalStatusValue(user?.userInfo.maritalStatus),
    date: user?.userInfo.birthday,
    email: user?.username || '',
    phone: user?.userInfo.phone || '',
    cellPhone: user?.userInfo.cellPhone || '',
    street: user?.userInfo.address.street || '',
    extNumber: user?.userInfo.address.extNumber || '',
    intNumber: user?.userInfo.address.intNumber || '',
    colony: user?.userInfo.address.colony || '',
    cp: user?.userInfo.address.cp || '',
    delegation: user?.userInfo.address.delegation || '',
    state: user?.userInfo.address.state || '',
    insurerName: user?.userInfo.medicalInsurance?.name || '',
    policyNumber: user?.userInfo.medicalInsurance?.policyNumber || '',
    emergencyContactName: user?.userInfo.emergencyContact[0].name || '',
    emergencyContactRelationship:
      user?.userInfo.emergencyContact[0].relationship || '',
    emergencyContactPhone: user?.userInfo.emergencyContact[0].phone || '',
    doctor: user
      ? {
          value: user?.doctorData?.id.toString(),
          label: getCompleteName(
            { ...user?.doctorData, userType: UserType.DOCTOR } as User,
            true
          ),
        }
      : undefined,
    currentOccupation: user?.userInfo.currentOccupation || '',
    previousOccupation: user?.userInfo.previousOccupation || '',
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('required')),
    firstLastName: yup.string().required(t('required')),
    secondLastName: yup.string().required(t('required')),
    bloodType: yup.string().required(t('required')),
    gender: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    maritalStatus: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    date: yup
      .date()
      .required(t('required'))
      .max(new Date(), t('date.invalid'))
      .typeError(t('date.format')),
    email: yup.string().email(t('email.format')).required(t('required')),
    phone: yup.string().required(t('required')).min(10, t('phone.length')),
    cellPhone: yup.string().required(t('required')).min(10, t('phone.length')),
    street: yup.string().required(t('required')),
    extNumber: yup.string().required(t('required')),
    colony: yup.lazy((value) =>
      typeof value === 'string'
        ? yup.string().required(t('required'))
        : yup.object().shape({
            value: yup.string().required(t('required')),
          })
    ),
    cp: yup.string().required(t('required')).min(5, t('cp.length')),
    delegation: yup.string().required(t('required')),
    state: yup.string().required(t('required')),
    emergencyContactName: yup.string().required(t('required')),
    emergencyContactRelationship: yup.string().required(t('required')),
    emergencyContactPhone: yup
      .string()
      .required(t('required'))
      .min(10, t('phone.length')),
    doctor: yup
      .object()
      .shape({
        value: yup.string().required(t('required')),
      })
      .nullable()
      .required(t('required')),
    currentOccupation: yup.string().required(t('required')),
    previousOccupation: yup.string().required(t('required')),
  });

  const methods = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const submit: SubmitHandler<FormFields> = async (fields) => {
    const userInfo = {
      name: fields.name,
      firstLastName: fields.firstLastName,
      secondLastName: fields.secondLastName,
      bloodType: fields.bloodType,
      gender:
        fields.gender?.value === Gender.MASCULINE
          ? Gender.MASCULINE
          : Gender.FEMALE,
      maritalStatus: fields.maritalStatus?.value || '',
      birthday: moment(fields.date).format('YYYY-MM-DD'),
      phone: fields.phone,
      cellPhone: fields.cellPhone,
      address: {
        street: fields.street,
        extNumber: fields.extNumber,
        intNumber: fields.intNumber,
        cp: fields.cp,
        state: fields.state,
        delegation: fields.delegation,
        colony:
          (typeof fields.colony == 'string'
            ? fields.colony
            : fields.colony?.value) || '',
      },
      medicalInsurance: {
        name: fields.insurerName,
        policyNumber: fields.policyNumber,
      },
      emergencyContact: [
        {
          name: fields.emergencyContactName,
          phone: fields.emergencyContactPhone,
          relationship: fields.emergencyContactRelationship,
        },
      ],
      previousOccupation: fields.previousOccupation,
      currentOccupation: fields.currentOccupation,
      profileImage: {
        mediaType: MediaType.IMAGE,
      },
    } as UserInfo;

    await axn({
      username: fields.email,
      userInfo,
      doctorId: Number.parseInt(fields.doctor?.value || '0'),
      userId: user?.id,
    });
  };

  return {
    error,
    loading,
    methods,
    processedUsername,
    submit,
  };
};
