import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import { InputLabel, Layout } from 'telemed-core';

import { HelpForm } from '../forms';

const keyPrefix = 'container.HelpContainer';

export const HelpContainer: React.FC<RouteComponentProps> = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });

  return (
    <Layout title={t('title')}>
      <Container maxWidth='md' sx={{ marginTop: (theme) => theme.spacing(5) }}>
        <InputLabel label={t('instructions')} />
        <HelpForm />
      </Container>
    </Layout>
  );
};
