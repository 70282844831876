import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UserLoginParams, useTelemed } from 'telemed-core';

const keyPrefix = 'general';

export const useLoginFormManagement = () => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { login, error, loading } = useTelemed();

  const schema = yup.object().shape({
    username: yup.string().email(t('tryEmail')).required(t('required')),
    password: yup.string().required(t('required')),
  });

  const methods = useForm<UserLoginParams>({
    resolver: yupResolver(schema),
  });

  const submit: SubmitHandler<UserLoginParams> = (data) => {
    login(data);
  };

  return {
    error,
    loading,
    methods,
    submit,
  };
};
