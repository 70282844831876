import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import {
  Button,
  Equipment,
  Modal,
  Loading,
  PhotoUploadInput,
  BasicTextInput,
} from 'telemed-core';

import { EquipmentFormFields, useEquipmentFormManagement } from '../hooks';
import Stack from '@material-ui/core/Stack';

const keyPrefix = 'forms.EquipmentForm';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  equipment: Equipment;
}

export const EquipmentForm: React.FC<Props> = ({
  className,
  equipment,
}): JSX.Element => {
  const [photo, setPhoto] = useState<File | null>(null);
  const { error, loading, methods, successSubmit, submit } =
    useEquipmentFormManagement(equipment);
  const { t } = useTranslation('translation', { keyPrefix });
  const navigate = useNavigate();

  const handleSelectFile = (file: File | null) => {
    setPhoto(file);
  };

  const handleClickOk = () => {
    navigate('/equipment/list');
  };

  const onSubmit = (fields: EquipmentFormFields) => {
    submit({
      fields,
      photo,
      validateFileUploadOptions: {
        maxSizeText: t('submit.validation.maxSizeText'),
        badExtensionText: t('submit.validation.badExtensionText'),
        extensionRegExp: /(jpg|jpeg|png)/s,
      },
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
          <Stack
            display='grid'
            alignItems='end'
            direction='row'
            columnGap={2}
            gridTemplateColumns='3fr 2fr'
          >
            <Box>
              <PhotoUploadInput
                {...(!!equipment &&
                !!equipment.inventory.inventoryInfo.media?.url
                  ? {
                      initialMedia: {
                        url: equipment.inventory.inventoryInfo.media.url,
                        mediaType:
                          equipment.inventory.inventoryInfo.media.mediaType,
                      },
                    }
                  : {})}
                onSelectFile={handleSelectFile}
              />
            </Box>
            <Stack direction='column' spacing={0.5}>
              <BasicTextInput
                name='name'
                label={t('name.label')}
                maxLength={30}
              />
              <BasicTextInput
                name='brand'
                label={t('brand.label')}
                maxLength={30}
              />
              <BasicTextInput
                name='model'
                label={t('model.label')}
                maxLength={30}
              />
              <BasicTextInput
                name='serie'
                label={t('serie.label')}
                maxLength={30}
              />
              <BasicTextInput
                name='description'
                label={t('description.label')}
                maxLength={300}
                minRows={8}
              />
            </Stack>
          </Stack>
          <Box
            sx={{
              m: (theme) => `${theme.spacing(5)} auto`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('modals.error.title')} message={error} />}
      {successSubmit &&
        (!!equipment ? (
          <Modal
            title={t('modals.success.update.title')}
            message={t('modals.success.update.message')}
            onClickOk={handleClickOk}
          />
        ) : (
          <Modal
            title={t('modals.success.add.title')}
            message={t('modals.success.add.message')}
            onClickOk={handleClickOk}
          />
        ))}
    </>
  );
};
