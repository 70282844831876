import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const keyPrefix = 'general';

export interface HelpFormFields {
  problemName: string | null;
  problemDescription: string | null;
}

export const useHelpFormManagement = (
  send: (problemName: string, problemDescription: string) => void
) => {
  const { t } = useTranslation('translation', { keyPrefix });

  const defaultValues = {
    problemName: null,
    problemDescription: null,
  };

  const schema = yup.object().shape({
    problemName: yup.string().typeError(t('required')).required(t('required')),
    // problemDescription: yup
    //   .string()
    //   .typeError(t('required'))
    //   .required(t('required')),
  });

  const methods = useForm<HelpFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const submit: SubmitHandler<HelpFormFields> = async ({
    problemName,
    problemDescription,
  }) => {
    await send(problemName!, problemDescription!);
    methods.reset();
  };

  return { methods, submit };
};
