import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { TelemedProvider, theme } from 'telemed-core';
import './i18n';

import Routes, { menuOptions } from './routes';

ReactDOM.render(
  <React.StrictMode>
    <TelemedProvider versionApp='1.0' skuApp='admin' menuOptions={menuOptions}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </TelemedProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
