import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import {
  AddressInput,
  BloodTypeInput,
  Button,
  DateInput,
  EmailInput,
  GenderInput,
  MaritalStatusInput,
  Modal,
  NameInput,
  Loading,
  PhoneInput,
  ProfessionalLicenseInput,
  SpecialtyInput,
  UploadButtonInput,
  User,
} from 'telemed-core';
import 'moment/locale/es-mx';

import { DoctorFormFields, useDoctorFormManagement } from '../hooks';

const keyPrefix = 'forms.DoctorForm';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
}

export const DoctorForm: React.FC<Props> = ({
  className,
  user,
}): JSX.Element => {
  const [certificate, setCertificate] = useState<File | null>(null);
  const { error, loading, methods, processedUsername, submit } =
    useDoctorFormManagement(user);
  const { t } = useTranslation('translation', { keyPrefix });
  const navigate = useNavigate();

  const handleSelectFile = (file: File | null) => {
    setCertificate(file);
  };

  const handleClickOk = () => {
    navigate('/doctor/list');
  };

  const onSubmit = (fields: DoctorFormFields) => {
    submit({
      fields,
      certificate,
      validateFileUploadOptions: {
        maxSizeText: t('submit.validation.maxSizeText'),
        requiredText: t('submit.validation.requiredText'),
      },
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
          <NameInput
            label={t('name.label')}
            placeholders={{
              name: t('name.placeholder.firstName'),
              firstLastName: t('name.placeholder.firstLastName'),
              secondLastName: t('name.placeholder.secondLastName'),
            }}
          />

          <Box
            sx={{
              pt: (theme) => theme.spacing(2.5),
              pb: (theme) => theme.spacing(2.5),
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(3,1fr)',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <GenderInput
              label={t('gender.label')}
              placeholder={t('gender.placeholder')}
            />
            <MaritalStatusInput
              label={t('maritalStatus.label')}
              placeholder={t('maritalStatus.placeholder')}
            />
            <DateInput
              label={t('birthday.label')}
              placeholder={t('birthday.placeholder')}
            />
          </Box>
          <Box
            sx={{
              pb: (theme) => theme.spacing(2.5),
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(3,1fr)',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <BloodTypeInput
              label={t('bloodType.label')}
              placeholder={t('bloodType.placeholder')}
            />
            <EmailInput
              label={t('email.label')}
              placeholder={t('email.placeholder')}
              disabled={!!user}
            />
            <PhoneInput
              name='cellPhone'
              label={t('cellPhone.label')}
              placeholder={t('cellPhone.placeholder')}
            />
          </Box>
          <AddressInput
            label={t('address.label')}
            placeholders={{
              street: t('address.placeholders.street'),
              extNumber: t('address.placeholders.extNumber'),
              intNumber: t('address.placeholders.intNumber'),
              cp: t('address.placeholders.cp'),
              state: t('address.placeholders.state'),
              delegation: t('address.placeholders.delegation'),
              colony: t('address.placeholders.colony'),
              extraInfo: t('address.placeholders.extraInfo'),
            }}
            {...(!!user
              ? {
                  initialSets: {
                    cp: user.userInfo.address.cp,
                    colony: user.userInfo.address.colony,
                  },
                }
              : {})}
          />

          <Box
            sx={{
              pt: (theme) => theme.spacing(1),
              pb: (theme) => theme.spacing(1),
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: '2fr 2fr 3fr',
              columnGap: (theme) => theme.spacing(2),
            }}
          >
            <ProfessionalLicenseInput
              label={t('professionalLicense.label')}
              placeholder={t('professionalLicense.placeholder')}
            />
            <SpecialtyInput
              label={t('speciality.label')}
              placeholder={t('speciality.placeholder')}
            />
            <UploadButtonInput
              label={t('file.label')}
              placeholder={t('file.placeholder')}
              buttonText={t('file.buttonText')}
              onSelectFile={handleSelectFile}
            />
          </Box>
          <Box
            sx={{
              m: (theme) => `${theme.spacing(5)} auto`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('error.title')} message={error} />}
      {!!processedUsername &&
        (!!user ? (
          <Modal
            title={t('success.update.title')}
            message={t('success.update.message')}
            onClickOk={handleClickOk}
          />
        ) : (
          <Modal
            title={t('success.add.title')}
            message={t('success.add.message', { email: processedUsername })}
            onClickOk={handleClickOk}
          />
        ))}
    </>
  );
};
