import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  AddButton,
  Layout,
  useUsersLazy,
  User,
  UserListItem,
  UserType,
  EditSvg,
  MailSvg,
  Filter,
  getCompleteName,
  useEmailResend,
  Loading,
  Modal,
} from 'telemed-core';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Moment from 'react-moment';
import 'moment/locale/es-mx';

const keyPrefix = 'container.DoctorListContainer';

export const DoctorListContainer: React.FC<
  RouteComponentProps
> = (): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading, getByType } = useUsersLazy();
  const [list, setList] = useState<User[]>([]);
  const [filteredList, setFilteredList] = useState<User[]>([]);
  const navigate = useNavigate();

  const {
    error: resendError,
    loading: resendLoading,
    username,
    send,
  } = useEmailResend();

  const getDoctors = useCallback(
    async function () {
      const doctors = await getByType(UserType.DOCTOR);
      setList(doctors);
      setFilteredList(doctors);
    },
    [getByType, setList, setFilteredList]
  );

  useEffect(() => {
    getDoctors();
  }, [getDoctors, getByType]);

  const add = useCallback(() => {
    navigate('/doctor', { state: { user: null } });
  }, [navigate]);

  const edit = useCallback(
    (user: User) => {
      navigate('/doctor', { state: { user } });
    },
    [navigate]
  );

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.trim() === '') {
      setFilteredList(list);
      return;
    }

    const filteredList = list.filter((user) => {
      const name = getCompleteName(user, true)
        .replaceAll(' ', '')
        .toLowerCase();
      const clearValue = value.replaceAll(' ', '').toLowerCase();
      return name.includes(clearValue);
    });

    setFilteredList(filteredList);
  };

  const resend = useCallback(
    ({ username }: User) => {
      send(username!);
    },
    [send]
  );

  return (
    <>
      <Layout title={t('title')} error={error} loading={loading}>
        <Container
          maxWidth='md'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: (theme) => theme.spacing(5),
            marginBottom: (theme) => theme.spacing(3),
          }}
        >
          <Box width='60%'>
            <Filter
              filterText={t('filter.text')}
              placeholder={t('filter.placeholder')}
              onChange={handleChange}
            />
          </Box>
          <AddButton label={t('AddButton.label')} onClick={add} />
        </Container>
        <Container sx={{ width: '680px' }}>
          <List>
            {filteredList.map((user: User, idx: number) => {
              const {
                creationDate,
                userInfo: { speciality },
              } = user;
              return (
                <UserListItem
                  key={idx}
                  user={user}
                  extraPostData={
                    <Typography
                      variant='body2'
                      color='grey.300'
                      sx={{
                        opacity: 0.7,
                      }}
                    >
                      {t('creation')}
                      <Moment fromNow locale='es-mx'>
                        {creationDate}
                      </Moment>
                    </Typography>
                  }
                  rightCol={
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '110px 50px 50px',
                        alignItems: 'center',
                        columnGap: (theme) => theme.spacing(2),
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{
                          opacity: 0.65,
                        }}
                      >
                        {speciality}
                      </Typography>
                      <IconButton
                        size='large'
                        color='primary'
                        onClick={() => {
                          resend(user);
                        }}
                      >
                        <MailSvg height={26} />
                      </IconButton>
                      <IconButton
                        size='large'
                        color='primary'
                        onClick={() => {
                          edit(user);
                        }}
                      >
                        <EditSvg height={26} />
                      </IconButton>
                    </Box>
                  }
                />
              );
            })}
          </List>
        </Container>
      </Layout>
      {loading && <Loading />}
      {!!resendError && (
        <Modal title={t('modals.error.title')} message={resendError} />
      )}
      {!!username && (
        <Modal
          title={t('modals.success.resend.title')}
          message={t('modals.success.resend.message', { username })}
        />
      )}
    </>
  );
};
