import { Box, Switch as MuiSwitch, Typography } from '@material-ui/core';
import React from 'react';
import { UserStatus } from 'telemed-core';

interface Props {
  activeText?: string;
  definitiveUnsubscribeText?: string;
  status: UserStatus;
  statusDescription?: string;
  temporaryUnsubscribeText?: string;
  onChange: (checked: boolean) => void;
}

export const Switch: React.FC<Props> = ({
  activeText = 'Activo',
  definitiveUnsubscribeText = 'Baja Definitiva',
  status,
  statusDescription,
  temporaryUnsubscribeText = 'Baja Temporal',
  onChange,
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: (theme) => theme.spacing(1),
      }}
    >
      <Typography
        component='span'
        variant='caption'
        sx={{ fontSize: 10, textAlign: 'center', lineHeight: 1.2 }}
      >
        {status === UserStatus.ACTIVE && activeText}
        {status === UserStatus.TEMPORARY_UNSUBSCRIBE &&
          temporaryUnsubscribeText}
        {status === UserStatus.DEFINITIVE_UNSUBSCRIBE &&
          definitiveUnsubscribeText}
      </Typography>
      {status !== UserStatus.DEFINITIVE_UNSUBSCRIBE && (
        <MuiSwitch
          checked={status === UserStatus.ACTIVE}
          size='small'
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
      )}
      <Typography
        component='span'
        sx={{
          marginTop: '0.3rem',
          fontSize: '0.7rem',
          textAlign: 'center',
          lineHeight: '0.65rem',
        }}
      >
        {statusDescription && statusDescription}
      </Typography>
    </Box>
  );
};
