import React, { useCallback, useState } from 'react';
import { useNavigate } from '@reach/router';
import { styled } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Content, EditSvg, MediaType } from 'telemed-core';
import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import IconButton from '@material-ui/core/IconButton';

const Img = styled('img')({
  maxWidth: '85vw',
  maxHeight: '85vh',
  height: 'auto',
  width: 'auto',
});

export const ContentCardItem: React.FC<Content> = (content): JSX.Element => {
  const {
    name,
    description,
    contentInfo: {
      media: { url, mediaType, urlVideoThumbnail },
    },
  } = content;

  const navigate = useNavigate();
  const [lightBox, setLightBox] = useState<boolean>(false);

  const handleClick = () => {
    if (mediaType === MediaType.IMAGE) {
      setLightBox(true);
    }
  };

  const handleCloseLightBox = () => {
    setLightBox(false);
  };

  const edit = useCallback(() => {
    navigate('/content', { state: { content } });
  }, [navigate, content]);

  return (
    <>
      <Card component='li'>
        <CardContent
          sx={{
            height: '100%',
            display: 'grid',
            gridTemplateRows: `1fr 100px`,
            alignItems: 'center',
            paddingBottom: '0 !important',
          }}
        >
          <CardMedia
            {...(MediaType.IMAGE && { component: 'img' })}
            sx={{
              maxWidth: mediaType === MediaType.VIDEO ? 350 : 218,
              maxHeight: mediaType === MediaType.VIDEO ? 350 : 218,
              width: mediaType === MediaType.VIDEO ? 350 : 'auto',
              height: mediaType === MediaType.VIDEO ? 218 : 'auto',
              cursor: mediaType === MediaType.IMAGE ? 'pointer' : 'default',
            }}
            {...(mediaType === MediaType.IMAGE && {
              image: url,
              cursor: 'pointer',
            })}
            {...(mediaType === MediaType.VIDEO && {
              src: url,
              component: 'video',
              controls: true,
            })}
            {...(mediaType === MediaType.VIDEO &&
              !!urlVideoThumbnail && { poster: urlVideoThumbnail })}
            onClick={handleClick}
          />
          <Box>
            <Typography variant='subtitle1' color='grey.300'>
              {name}
            </Typography>
            <Stack direction='row' justifyContent='space-between'>
              <Typography
                variant='body2'
                color='grey.300'
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  height: '100%',
                }}
              >
                {description.length <= 50
                  ? description
                  : `${description.substring(0, 50)}...`}
              </Typography>
              <IconButton size='large' color='primary' onClick={edit}>
                <EditSvg width={24} height={24} />
              </IconButton>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Modal
        sx={{
          zIndex: 3000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={lightBox}
        onClose={handleCloseLightBox}
        keepMounted
      >
        <Img src={url} />
      </Modal>
    </>
  );
};
