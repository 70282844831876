import Grid from '@material-ui/core/Grid';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton } from '@material-ui/core';
import {
  Button,
  BasicTextInput,
  RadioGroupInput,
  UserStatus,
} from 'telemed-core';

import { usePatientStatusFormManagement } from '../hooks';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onBack: () => void;
  updateStatus: (
    status: UserStatus,
    statusDescription?: string | undefined
  ) => Promise<void>;
}
const keyPrefix = 'forms.PatientStatusForm';

export const PatientStatusForm: React.FC<Props> = ({
  className,
  onBack,
  updateStatus,
}): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { methods, submit } = usePatientStatusFormManagement(
    updateStatus,
    onBack
  );

  const options = [
    {
      value: UserStatus.TEMPORARY_UNSUBSCRIBE.toString(),
      label: t('unsubscribeType.temporary'),
    },
    {
      value: UserStatus.DEFINITIVE_UNSUBSCRIBE.toString(),
      label: t('unsubscribeType.definitive'),
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} className={className}>
        <RadioGroupInput name='unsubscribeType' options={options} />
        <BasicTextInput
          name='statusDescription'
          maxLength={30}
          // minRows={2}
          label={t('statusDescription.label')}
          placeholder={t('statusDescription.placeholder')}
        />
        <Grid
          container
          spacing={4}
          alignItems='center'
          marginTop={0}
          sx={{ width: '100%' }}
        >
          <Grid item md={6}>
            <MuiButton variant='text' color='secondary' onClick={onBack}>
              {t('back.label')}
            </MuiButton>
          </Grid>
          <Grid
            item
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
