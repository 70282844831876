import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import VideoImageThumbnail from 'react-video-thumbnail-image';
import {
  Button,
  Content,
  Modal,
  Loading,
  PhotoUploadInput,
  BasicTextInput,
} from 'telemed-core';

import { ContentFormFields, useContentFormManagement } from '../hooks';
import Stack from '@material-ui/core/Stack';

const keyPrefix = 'forms.ContentForm';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  content: Content;
}

export const ContentForm: React.FC<Props> = ({
  className,
  content,
}): JSX.Element => {
  const [snapShotAtTime, setSnapShotAtTime] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoThumbnail, setVideoThumbnail] = useState<File | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix });
  const { error, loading, methods, successSubmit, submit } =
    useContentFormManagement(content);

  const handleSelectFile = (file: File | null) => {
    setFile(file);
  };

  const handleClickOk = () => {
    navigate('/content/list');
  };

  const onSubmit = (fields: ContentFormFields) => {
    submit({
      fields,
      file,
      videoThumbnail,
      validateFileUploadOptions: {
        maxSizeText: t('submit.validation.maxSizeText'),
        badExtensionText: t('submit.validation.badExtensionText'),
        extensionRegExp: /(jpg|jpeg|png|mp4)/s,
      },
    });
  };

  const getSnapshotTimeVideo = (file: File) => {
    const url = window.URL.createObjectURL(file);
    try {
      let video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        const time = Math.floor(video.duration / 2);
        setSnapShotAtTime(time);
      };

      video.onerror = function () {
        console.error('at intent get snapshot time');
      };

      video.src = url;
    } catch (e) {
      console.log('error', e);
    }
    return url;
  };

  useEffect(() => {
    if (!file) {
      setVideoThumbnail(null);
      return;
    }
    if (!file.type.includes('video')) {
      setVideoThumbnail(null);
      return;
    }

    const objectUrl = getSnapshotTimeVideo(file);
    setVideoUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const b64ToFile = async (dataUrl: string) => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], 'thumbnail.png', { type: 'image/png' });
  };

  return (
    <>
      {!!videoUrl && (
        <div style={{ height: 0, display: 'none' }}>
          <VideoImageThumbnail
            videoUrl={videoUrl}
            thumbnailHandler={async (thumbnail: string) => {
              const videoThumbnail = await b64ToFile(thumbnail);
              setVideoThumbnail(videoThumbnail);
            }}
            renderThumbnailHtml={false}
            snapshotAtTime={snapShotAtTime}
          />
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
          <Stack
            display='grid'
            alignItems='start'
            direction='row'
            columnGap={2}
            gridTemplateColumns='3fr 2fr'
          >
            <Box>
              <PhotoUploadInput
                {...(!!content && !!content.contentInfo.media?.url
                  ? {
                      initialMedia: {
                        url: content.contentInfo.media.url,
                        mediaType: content.contentInfo.media.mediaType,
                      },
                    }
                  : {})}
                onSelectFile={handleSelectFile}
                acceptVideo={true}
                label={t('file.label')}
              />
            </Box>
            <Stack direction='column' spacing={0.5}>
              <BasicTextInput
                name='name'
                label={t('name.label')}
                maxLength={30}
              />
              <BasicTextInput
                name='description'
                label={t('description.label')}
                maxLength={300}
                minRows={8}
              />
            </Stack>
          </Stack>
          <Box
            sx={{
              m: (theme) => `${theme.spacing(5)} auto`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button buttonProps={{ type: 'submit' }} gray>
              {t('submit.label')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      {loading && <Loading />}
      {!!error && <Modal title={t('modals.error.title')} message={error} />}
      {successSubmit &&
        (!!content ? (
          <Modal
            title={t('modals.success.update.title')}
            message={t('modals.success.update.message')}
            onClickOk={handleClickOk}
          />
        ) : (
          <Modal
            title={t('modals.success.add.title')}
            message={t('modals.success.add.message')}
            onClickOk={handleClickOk}
          />
        ))}
    </>
  );
};
