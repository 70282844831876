import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTheme } from '@material-ui/core/styles';
import {
  Chart,
  Title,
  ColumnSeries,
  HighchartsChart,
  Legend,
  XAxis,
  YAxis,
} from 'react-jsx-highcharts';

import { ChartData } from 'telemed-core';

type Props = {
  chartData: ChartData[];
  className?: string;
};

export const GraphicViewerCard: React.FC<Props> = ({
  chartData,
  className,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Card
      elevation={3}
      sx={{
        width: '90%',
        height: '90%',
        margin: 'auto',
      }}
      className={className}
    >
      <CardContent sx={{ transform: 'scale(0.8)' }}>
        <HighchartsChart
          lang={{
            noData: '-- Sin datos para mostrar --',
          }}
          containerProps={{
            width: '100%',
            padding: '16px',
          }}
          plotOptions={{
            column: {
              dataLabels: {
                enabled: true,
                // style: {
                //   // color: theme.palette.primary.main,
                // },
              },
            },
          }}
        >
          <Title
            style={{
              fontFamily: 'AntennaBeta',
              fontWeight: 'bold',
              // textTransform: 'uppercase',
              // color: theme.palette.info.dark,
              color: theme.palette.primary.main,
            }}
          >
            {chartData[0].title}
          </Title>
          {chartData.length > 1 && <Legend />}
          <Chart
            style={{
              fontFamily: 'AntennaCondBeta',
            }}
          />
          <XAxis type='category' />
          <YAxis visible={false}>
            {chartData.map((props, index) => (
              <ColumnSeries key={index} {...props} />
            ))}
          </YAxis>
        </HighchartsChart>
      </CardContent>
    </Card>
  );
};
